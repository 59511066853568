<template>
  <div class="list-view product-checkout mt-0">
    <div class="checkout-items-wrap">
      <b-card>
        <div class="checkout_buttons">
          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="$t('form.price.label')"
                label-for="price"
              >
                <b-form-input
                  id="price"
                  v-model="getItems.price"
                  :state="errors && errors.price ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.price">
                  {{ errors.price[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-group
                :label="$t('form.type.label')"
                label-for="format"
                :state="errors && errors.format ? false : null"
              >
                <v-select
                  id="format"
                  v-model="getItems.format"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="priceTypeFilterOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="format"
                />
                <b-form-invalid-feedback v-if="errors && errors.format">
                  {{ errors.format[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="outline-primary"
            @click="updateChannels"
          >
            {{ $t('cart.page.btn.updateChannels') }}
          </b-button>
        </div>
      </b-card>
      <div class="checkout-items">
        <b-card
          v-for="(item, index) in items"
          :key="item.product.slug"
          v-b-tooltip.hover.v-primary
          class="ecommerce-card"
          no-body
          :title="`
          ${$t('catalog.item.labels.subscribers')} - ${$options.filters.numberFormat(getHistory(item).subscribers)};
          ${$t('catalog.item.labels.views')} - ${$options.filters.fFormat(getHistory(item).views)};
          ${$t('catalog.item.labels.er')} - ${$options.filters.numberFormat(getHistory(item).subscribers)};
          ${$t('catalog.item.labels.cpm')} - ${$options.filters.moneyFormat(getHistory(item).cpv * 1000)};
          `"
        >
          <div class="item-img">
            <b-link :to="{ name: 'products-show', params: { slug: item.product.slug } }">
              <b-img
                :alt="`${item.product.slug}-${item.product.id}`"
                :src="$options.filters.mediaUrl(item.product, 'avatar', '320x320')"
              />
            </b-link>
          </div>
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-0">
                <b-link
                  :to="{ name: 'products-show', params: { slug: item.product.slug } }"
                  class="text-body"
                >
                  {{ $options.filters.transl(item.product, 'title') }}
                </b-link>
              </h6>
            </div>
          </b-card-body>
          <div class="item-options">
            <div class="item-wrapper">
              <div
                v-if="calcDiscount(item)"
                class="item-cost"
              >
                <del class="item-price">
                  {{ priceFormatted(calcPrice(item)) }}
                </del>
                <h4 class="item-price text-danger">
                  {{ priceFormatted(calcDiscount(item)) }}
                </h4>
              </div>
              <div
                v-else
                class="item-cost"
              >
                <h4 class="item-price">
                  {{ priceFormatted(calcPrice(item)) }}
                </h4>
              </div>
            </div>
          </div>
          <div class="item-options text-right">
            <b-button
              variant="light"
              class="remove-wishlist"
              @click="removeItemFromCart(index)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
    <div>
      <b-card>
        <div class="price-details">
          <h6 class="price-title">
            {{ $t('cart.page.titles.totals') }}
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail d-flex justify-content-between">
              <div class="detail-title">
                {{ $t('cart.page.titles.channels') }}
              </div>
              <div class="detail-amt">
                {{ totalChannels }}
              </div>
            </li>
            <li class="price-detail d-flex justify-content-between">
              <div class="detail-title">
                {{ $t('cart.page.titles.quantity') }}
              </div>
              <div class="detail-amt discount-amt text-success">
                {{ totalQuantity }}
              </div>
            </li>
            <li class="price-detail d-flex justify-content-between">
              <div class="detail-title">
                {{ $t('catalog.item.labels.subscribers') }}
              </div>
              <div class="detail-amt">
                {{ $options.filters.numberFormat(totalSubscribers) }}
              </div>
            </li>
            <li class="price-detail d-flex justify-content-between">
              <div class="detail-title">
                {{ $t('catalog.item.labels.views') }}
              </div>
              <div class="detail-amt">
                ~ {{ $options.filters.fFormat(totalViews) }}
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail d-flex justify-content-between">
              <div class="detail-title detail-total">
                {{ $t('cart.widget.titles.total') }}
              </div>
              <div class="detail-amt font-weight-bolder">
                {{ this.$options.filters.moneyFormat(subTotalAmount) }}
              </div>
            </li>
          </ul>
          <b-button
            v-if="canToOrder"
            variant="primary"
            block
            @click="placeOrder"
          >
            {{ $t('cart.page.btn.order') }}
          </b-button>
          <template v-else>
            <b-alert
              variant="danger"
              :show="true"
              class="mb-1"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                {{ $t('cart.page.titles.empty_money') }}
              </div>
            </b-alert>
            <b-button
              variant="warning"
              block
              @click="openedAddFound = true;"
            >
              {{ $t('cart.page.btn.addFound') }}
            </b-button>
          </template>
        </div>
      </b-card>
    </div>

    <AddFound
      v-if="userData"
      :opened-add-found.sync="openedAddFound"
      :amount="500"
      :settings="userData.transaction_settings"
      @init-payment="initPayment"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { useEcommerceUi } from '@/views/catalog/useEcommerce'
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import AddFound from '@/views/account/setting/finance/AddFound'

export default {
  components: {
    AddFound,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      balance: 0,
      items: [],
      openedLoginModal: false,
      getItems: {
        price: 100,
        format: '1day',
      },
      userData: JSON.parse(localStorage.getItem('userData')) ?? null,
      wayforpay: null,
      openedAddFound: this.$route.query.action === 'found' ?? false,
    }
  },
  computed: {
    subTotalAmount() {
      let total = 0
      this.items.forEach(i => { total += (this.calcDiscount(i) || this.calcPrice(i)) })
      return total ? parseInt(total, 10) : 0
    },
    totalAmount() {
      return this.subTotalAmount
    },
    totalQuantity() {
      let total = 0
      this.items.forEach(i => { total += i.quantity })
      return total
    },
    totalChannels() {
      return this.items.length
    },
    totalSubscribers() {
      let total = 0
      this.items.forEach(i => { total += this.getHistory(i).subscribers })
      return total
    },
    totalViews() {
      let total = 0
      this.items.forEach(i => { total += this.getHistory(i).views * i.quantity })
      return total
    },
    canToOrder() {
      return !this.userData || this.balance >= this.totalAmount
    },
  },
  created() {
    if (this.userData) {
      this.$http.get('/api/profile').then(res => {
        const { balance } = res.data.data
        this.balance = balance
      })
    }
  },
  mounted() {
    this.updateChannels()
    const wayforpayScript = document.createElement('script')
    wayforpayScript.setAttribute('src', 'https://secure.wayforpay.com/server/pay-widget.js')
    document.head.appendChild(wayforpayScript)
  },
  methods: {
    selectedPrice(item) {
      return _.filter(item.product.prices, o => o.type === item.priceType)
    },
    selectedDiscount(item) {
      return item.hardPrice ? [{ price: item.hardPrice }] : _.filter(item.product.discounts, o => o.type === item.priceType)
    },
    calcPrice(item) {
      return this.selectedPrice(item) && this.selectedPrice(item).length ? this.selectedPrice(item)[0].price * item.quantity : 0
    },
    calcDiscount(item) {
      return this.selectedDiscount(item) && this.selectedDiscount(item).length ? this.selectedDiscount(item)[0].price * item.quantity : 0
    },
    priceFormatted(price) {
      return this.$options.filters.moneyFormat(price)
    },
    getHistory(item) {
      let h = {
        subscribers: 0,
        views: 0,
        er: 0,
        cpv: 0,
      }
      const koef = this.koefOptions[item.priceType]
      if (item.product.historyLast) {
        h = {
          subscribers: item.product.historyLast.subscribers,
          views: item.product.historyLast.views * koef,
          er: ((item.product.historyLast.views * koef) / item.product.historyLast.subscribers) * 100,
          cpv: (this.selectedDiscount(item) && this.selectedDiscount(item).length ? this.selectedDiscount(item)[0].price : this.selectedPrice(item)[0].price) / (item.product.historyLast.views * koef),
        }
      }
      return h
    },
    removeItemFromCart(index) {
      this.items.splice(index, 1)
    },
    async placeOrder() {
      const data = {
        products: this.items.map(i => ({
          product_id: i.product.id,
          quantity: i.quantity,
          type: i.priceType,
          hard_price: i.hardPrice,
        })),
      }

      this.$http.post('/api/user/projects_from_admin', data)
        .then(response => {
          router.replace({ name: 'admin:admin-projects-start', params: { id: response.data.data.id } })
            .then(() => {
              window.location.reload()
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    wfpInit() {
      this.wayforpay = new window.Wayforpay()
      window.addEventListener('message', event => {
        if (event.data === 'WfpWidgetEventClose'
          || event.data === 'WfpWidgetEventApproved'
          || event.data === 'WfpWidgetEventDeclined'
          // event.data === 'WfpWidgetEventPending' // транзакция на  обработке
        ) {
          this.wayforpay.closeit()
          this.wayforpay = null
          window.location.reload()
        }
      })
    },
    initPayment({ method, invoice }) {
      if (method === 'wayforpay' && invoice.url) {
        this.wfpInit()
        setTimeout(() => {
          this.wayforpay.invoice(invoice.url, true)
        }, 500)
      }
      if (method === 'cryptomus' && invoice.url) {
        window.location.href = invoice.url
      }
    },
    async updateChannels() {
      this.$http.get('/api/promo/products/', { params: { per_page: 9999, filter: `formats:${this.getItems.format}` } })
        .then(response => {
          if (response?.data?.data) {
            this.items = _.map(response.data.data, product => ({
              product,
              priceType: this.getItems.format,
              quantity: 1,
              hardPrice: this.getItems.price,
            }))
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
  setup() {
    const { handleCartActionClick } = useEcommerceUi()

    return {
      handleCartActionClick,
    }
  },
}
</script>

<style lang="scss">
.ecommerce-application .product-checkout.list-view {
  grid-template-columns: 2fr 1fr;
  column-gap: 2rem;
}

.checkout-items-wrap {
  .checkout-items {
    overflow-y: scroll;
    max-height: 500px;
    padding: 20px;
    margin: -20px;

    .ecommerce-card {
      grid-template-columns: 1fr 5fr 2fr 1fr !important;
      margin-bottom: 1rem;

      .item-img {
        img {
          width: 50px !important;
        }
      }

      .item-quantity {
        & > * {
          width:150px !important;
        }
      }

      .card-app-design {

        .design-planning-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr !important;
          align-items: stretch;
          gap:5px;
          margin:0 0;

          .design-planning {
            margin: 0 0 !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: help;

            &.disabled {
              filter:grayscale(1);
              pointer-events: none;
            }
          }

          &.type-1-1 {
            grid-template-columns: 2fr 1fr !important;
            margin:5px 0 0;

            .gender_icons {
              display: flex;
              justify-content: space-between;
              width: 100%;

              svg {
                width: 20px;
                height: 20px;
              }
            }

            .line {
              height: 4px;
              width: 100%;
              display: flex;
              background: #ea5455;
              border-radius: 2px;

              .blue {
                display: flex;
                height: 4px;
                background: #7367f0;
                border-radius: 2px;
              }
            }
          }
        }
      }

      @media (max-width: 860px) {
        grid-template-columns: 1fr !important;

        .item-img {
          img {
            border-radius: 5px;
          }
        }

        .item-quantity {
          & > * {
            width:100% !important;
          }
        }
      }
    }
  }
}

.checkout_buttons {
  display: grid;
  //grid-template-columns: 1fr 1fr;
  grid-template-columns: 1fr;
  gap: 1em;
}

@media screen and (max-width: 1360px) {
  .ecommerce-application .product-checkout.list-view {
    grid-template-columns: 1fr !important;
  }
}
</style>
